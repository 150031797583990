import gql from 'graphql-tag';
import apollo from '@/services/apollo';

export const loadAlbumQuery = (albumId: string, queryOptions?: object): Promise<any> => {
  const query = gql`
  query getAlbum($albumId: String!) {
    getAlbum(albumId: $albumId) {
      id
      createdBy{
        firstName
        id
        lastName
      }
      name
      pictures{
        id
        url
      }
    }
  }
`;

  return apollo.query({
    ...(queryOptions || {}),
    query,
    variables: {
      albumId,
    },
  });
};
