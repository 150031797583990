import Vue from 'vue';

import './AlbumDetail.css';
import gql from 'graphql-tag';
import Button from '@/components/Admin/Button/Button';
import apollo from '@/services/apollo';
import { AlbumModel, PictureModel } from '@/models/user.model';
import Loader from '@/components/Shared/Loader/Loader';
import PictureList from '@/components/Shared/PictureList/PictureList';
import { loadAlbumQuery } from '@/services/album';


const genericPreventDefault = (event: Event) => event.preventDefault();

export interface AlbumDetailData {
  album: AlbumModel | null;
  albumId: string;
  dragEventHandlers: {
    handler: (event: Event | any) => void;
    name: string;
    options?: object;
  }[];
  dragInProgress: boolean;
  filesToUpload: File[];
  loadingAlbum: boolean;
  uploadInProgress: boolean;
  uploadMessage: string;
}

const AlbumDetail = Vue.extend({
  beforeDestroy() {
    this.dragEventHandlers
      .forEach(({ name, handler }) => {
        document.getElementById('album-upload-area')?.removeEventListener(name, handler);
      });

    this.filesToUpload = [];
  },

  data(): AlbumDetailData {
    return {
      album: null,
      albumId: '',
      dragEventHandlers: [],
      dragInProgress: false,
      filesToUpload: [],
      loadingAlbum: true,
      uploadInProgress: false,
      uploadMessage: '',
    };
  },

  methods: {
    handleDeletePictureFromAlbum(pictureId: string) {
      // TODO: Use a modal
      const confirmed = window.confirm('Are you sure you want to delete this file');

      if (!confirmed || !this.album) return;

      this.album.pictures = (this.album.pictures || [])
        .filter(picture => picture.id !== pictureId);

      const mutation = gql`
        mutation removePictureFromAlbum(
          $albumId: String!
          $pictureId: String!
        ) {
          removePictureFromAlbum(
            albumId: $albumId
            pictureId: $pictureId
          ) {
            ok
          }
        }
      `;

      apollo.mutate({
        mutation,
        variables: {
          albumId: this.albumId,
          pictureId,
        },
      })
        .then(({ data }) => {
          const successful = data?.removePictureFromAlbum?.ok;

          if (successful) {
            // TODO: Flash notification
          }
        })
        .catch((err) => {
          console.log('Error deleting picture => ', err);
        });
    },

    handleDragEnd(event: Event) {
      event.preventDefault();
      event.stopPropagation();
      this.dragInProgress = false;
    },

    handleDragEnter() {
      this.dragInProgress = true;
    },

    handleDrop(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();
      this.dragInProgress = false;

      this.filesToUpload = [
        ...this.filesToUpload,
        ...(event.dataTransfer?.files || []),
      ]
        .filter(file => file.type.includes('image'));
    },

    loadAlbum() {
      this.loadingAlbum = true;

      const { albumId } = this.$route.params;

      this.albumId = albumId;

      loadAlbumQuery(albumId, { fetchPolicy: 'network-only' })
        .then(({ data }) => {
          this.album = data?.getAlbum;
        })
        .catch((err) => {
          // TODO: Handle error
          console.log('Error loading album => ', err);
        })
        .finally(() => {
          this.loadingAlbum = false;
        });
    },

    uploadPictures(event: Event) {
      event.preventDefault();
      this.uploadInProgress = true;
      this.uploadMessage = '';

      const mutation = gql`
        mutation addPicturesToAlbum(
          $albumId: String
          $pictures: [Upload!]!
        ) {
          addPicturesToAlbum(
            albumId: $albumId
            pictures: $pictures
          ) {
            failed
            successful{
              id
              name
            }
          }
        }
      `;

      apollo.mutate({
        mutation,
        variables: {
          albumId: this.albumId,
          pictures: this.filesToUpload,
        },
      })
        .then(({ data }) => {
          // TODO: Show failed uploads
          const successfulResponse: PictureModel[] = data?.addPicturesToAlbum?.successful || [];

          const successfulUploads = successfulResponse.map(upload => upload.name);
          const totalFiles = [...this.filesToUpload].length;

          this.uploadMessage = `${successfulResponse.length} of ${totalFiles} successfully uploaded`;

          // Get the successful uploads and create URLs for them
          const withPictureModelData = this.filesToUpload
            .filter(file => successfulUploads.includes(file.name))
            .map(file => ({
              // TODO: This can possibly be optimised
              id: successfulResponse.find(i => i.name === file.name)?.id || '',
              name: file.name,
              url: URL.createObjectURL(file),
            }));

          if (this.album) {
            this.album.pictures = [
              ...(this.album.pictures || []),
              ...withPictureModelData,
            ];
          }

          this.filesToUpload = this.filesToUpload.filter(
            file => !successfulUploads.includes(file.name),
          );
        })
        .catch((err) => {
          console.log('Error uploading pictures => ', err);
        })
        .finally(() => {
          this.uploadInProgress = false;
        });
    },
  },

  mounted() {
    this.loadAlbum();
  },

  name: 'album-detail',

  render() {
    const dropAreaClasses = [
      'album-drop-area',
      this.dragInProgress && 'album-drop-area--drag-in-progress',
    ].filter(i => i).join(' ');

    if (this.loadingAlbum || !this.album) {
      return (
        <Loader />
      );
    }

    return (
      <div class='album-detail'>
        <header class="dashboard-header">
          <h1 class="dashboard-header__title">{this.album.name} </h1>

          <router-link
            class="dashboard-header__subtitle"
            to={`/admin/team/${this.album.createdBy.id}`}
          >
            By {this.album.createdBy.firstName} {this.album.createdBy.lastName}
          </router-link>
        </header>

        <div class="dashboard-content">
          <div
            class={dropAreaClasses}
            id="album-upload-area"
            {...{
              on: {
                dragenter: this.handleDragEnter,
                dragleave: this.handleDragEnd,
                dragover: genericPreventDefault,
                drop: this.handleDrop,
              },
            }}
          >

            {
              // TODO: Style preview list
              Array.isArray(this.filesToUpload) && this.filesToUpload.length > 0
                ? (
                  <div class='album-preview__container'>
                    <ul class='album-preview__list'>
                      {
                        this.filesToUpload.map(file => (
                          <li key={file.lastModified}>
                            <img
                              class="album-preview__image"
                              src={URL.createObjectURL(file)}
                              alt={file.name}
                            />
                          </li>
                        ))
                      }
                    </ul>

                    <div class="album-preview__actions">
                      <a
                        onClick={(event: Event) => this.uploadPictures(event)}
                      >
                        <Button primary loading={this.uploadInProgress}>
                          Upload {this.filesToUpload.length} file{this.filesToUpload.length > 1 && 's'}
                        </Button>
                      </a>
                    </div>
                  </div>
                )
                : (
                  <div class="album-drop-area__empty">
                    <span>
                      Drag pictures here to upload
                  </span>
                  </div>
                )
            }
          </div>

          <PictureList
            handleDelete={(id: string) => this.handleDeletePictureFromAlbum(id)}
            showActions
            actions={{
              delete: true,
              download: true,
            }}
            pictures={this.album.pictures}
          />

        </div>

      </div>
    );
  },

  watch: {
    $route: 'loadAlbum',
  },
});

export default AlbumDetail;
